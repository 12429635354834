import { lazy, Suspense } from 'react';
// import logo from './logo.svg';
// import './App.css';
import { AppStyled } from './App.style'
// import FormWrapper from './FormWrapper';
// import ProgressBar from './ProgressBar';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const LogIn = lazy(() => import('./pages/LogIn'));
const SignUp = lazy(() => import('./pages/SignUp'));
const Connect = lazy(() => import('./pages/Connect'));
const Connected = lazy(() => import('./pages/Connected'));
const OkRedirect = lazy(() => import('./pages/OkRedirect'));
const Setup = lazy(() => import('./pages/Setup'));

function App() {
  // const [currentStep, setCurrentStep] = useState(1);

  return (
    <AppStyled>
      {/* <ProgressBar currentStep = { currentStep } /> */}
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>
            <Route path="/login" component={LogIn}/>
            <Route path="/signup" component={SignUp}/>
            <Route path="/connect" component={Connect}/>
            <Route path="/connected" component={Connected}/>
            <Route path="/ok" component={OkRedirect}/>
            <Route path="/setup" component={Setup}/>
          </Switch>
        </Suspense>
      </Router>
    </AppStyled>
  );
}

export default App;
