import styled from 'styled-components';

export const AppStyled = styled.div`
  background-color: #F9F2E8;
  height: 100vh;
  width: 100vw;
  position: absolute;
`;

export const HeadStyled = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  & > div {
    height: 37px;
    width: 45px;
    background-color: red;
  }
`;

export const TitleStyled = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
`;

export const DescriptionStyled = styled.div`
  max-width: 410px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  a {
    margin-left: 5px;
  }
`;
